import {environment as env} from './environment.default';
import merge from 'lodash-es/merge';

export const environment = merge(env, {
  demo: true,
  environmentName: 'prod',
  features: {
    timeEntriesActualTime: false,
  },
}) as typeof env;
